import React, { useContext } from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

import Container from "../components/Container";
import {
  Divider,
  Grid,
  Typography
} from "@material-ui/core";

import { makeStyles } from "@material-ui/core";

import Layout from "../components/layout";
import Banner from "../components/Banner";

import useUrl from "../hooks/useUrl";
import useLanguage from "../hooks/useLanguage";

import i18nContext from "../i18n-context";
import ContactForm from "../components/contactForm";

const ContactPage = ({ data, pageContext }) => {
  const classes = useStyles();
  const url = useUrl();
  const lang = useLanguage();
  const i18n = useContext(i18nContext)[lang];
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));

  const { title, excerpt, translated } = pageContext;
  const sourceUrl = pageContext.featuredImage?.node?.sourceUrl || "";

  const detailObject = data.allWpOther.nodes.filter((detail) => {
    if (detail.slug.includes(lang)) return detail;
  })

  return (
    <Layout pageTranslations={translated}>
      <Helmet>
        <title>{`${i18n.websiteName} - ${title}`}</title>

        {/* og tags for facebook and linkedin (except og:type and og:locale) */}
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={excerpt} />
        <meta property="og:image" content={sourceUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <meta
          property="og:locale"
          content={lang === "en" ? "en_US" : lang === "tc" ? "zh_TW" : "zh_CN"}
        />

        {/* og tags for twitter */}
        <meta name="twitter:card" content="summary" />
        {/* <meta name="twitter:site" content="@nytimes" />
        <meta name="twitter:creator" content="@SarahMaslinNir" /> */}
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={excerpt} />
        <meta name="twitter:image" content={sourceUrl} />
      </Helmet>

      <Banner title={title} bannerUrl={sourceUrl} />
      <Container>
        <Typography
          variant="h4"
          className={classes.description}
          dangerouslySetInnerHTML={{ __html: excerpt }}
        />
        <ContactForm stringObj={i18n.contact}></ContactForm>

        <div className={classes.addressSection}>
          <Typography variant="h4" className={classes.addressHeading}>
            {i18n.contact.ourOffice}
          </Typography>
          <Divider className={classes.divider} />
          <Typography
            variant="body2"
            component="div"
            className={classes.address}
          >
            <Grid container spacing={2}>
              {Array.isArray(detailObject) && detailObject.length > 0 && detailObject.map((item, index) => {
                return (
                  <Grid item xs={12} sm={6} key={`address-${item.name}`}>
                    <p>
                      <strong>{item.name}</strong>
                    </p>
                    <p dangerouslySetInnerHTML={{ __html: item.description.replace(/\r\n/g, "<br />") }}></p>
                    <br />
                  </Grid>
                )
              })}
            </Grid>
          </Typography>
        </div>
      </Container>
    </Layout>
  );
};

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    fontFamily: "Open Sans",
    fontWeight: 400,
  },
  form: {
    marginBottom: theme.spacing(4),
    display: "block",
  },
  textField: {
    marginBottom: theme.spacing(3),
    [`& fieldset`]: {
      borderRadius: 0,
    },
    "& input": {
      fontSize: 18,
    },
    "& textarea": {
      fontSize: 18,
    },
  },
  submitButton: {
    borderRadius: 0,
    borderColor: theme.palette.error.main,
  },
  addressHeading: {
    fontFamily: "Open Sans",
  },
  divider: {
    backgroundColor: theme.palette.text.primary,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
    marginLeft: `-${theme.spacing(2)}px`,
    marginRight: `-${theme.spacing(2)}px`,
    [theme.breakpoints.up("lg")]: {
      marginLeft: `-${theme.spacing(4)}px`,
      marginRight: `-${theme.spacing(4)}px`,
    },
  },
  addressSection: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(8),
    },
    marginBottom: theme.spacing(8),
  },
  address: {
    "& p": {
      marginBottom: theme.spacing(1),
    },
  },
}));

// this dummy query is added to prevent the page loading failure due to a graphql error
export const query = graphql`
  query getContactQuery($locale: String) {
    wpPage(
      pageType: { pageType: { eq: "6" } }
      locale: { locale: { eq: $locale } }
    ) {
      id
      title
      uri
    }
    allWpOther(
      filter: {slug: {regex: "/contact-detail-/i"}}
      sort: {order: ASC, fields: slug}
    ) {
      nodes {
        name
        slug
        description
      }
    }
  }
`;

export default ContactPage;
