import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";

const Banner = (props) => {
  const classes = useStyles(props);
  const muiTheme = useTheme();
  const isPhone = useMediaQuery(muiTheme.breakpoints.down("xs"));

  const gridItemWidths = props.textOnRightHalfOnly
    ? {
        xs: 8,
        sm: 6,
        lg: 5,
        xl: 3,
      }
    : {
        xs: 12,
      };

  return (
    <Grid
      container
      alignItems={
        isPhone && props.textOnRightHalfOnly ? "flex-start" : "center"
      }
      justifyContent="flex-end"
      className={classes.bannerRoot}
    >
      <Grid item {...gridItemWidths}>
        <Container>
          <Typography variant="h3" className={classes.bannerText}>
            {props.title}
          </Typography>
        </Container>
      </Grid>
      {props.textOnRightHalfOnly && (
        <Hidden mdDown>
          <Grid item lg={1} xl={3} />
        </Hidden>
      )}
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  bannerRoot: {
    marginBottom: theme.spacing(2),
    background: (props) => `url(${props.bannerUrl}) center center`,
    backgroundSize: "cover !important",
    objectFit: "cover",

    height: "120vw",
    [theme.breakpoints.up("sm")]: {
      height: "26vw",
    },

    // height: 200,
    // overflow: "hidden",

    // [theme.breakpoints.down("xs")]: {
    //   paddingTop: (props) => (props.textOnRightHalfOnly ? theme.spacing(4) : 0),
    //   height: (props) => (props.textOnRightHalfOnly ? 400 : 200),
    // },
    // [theme.breakpoints.up("sm")]: {
    //   height: (props) => (props.textOnRightHalfOnly ? 300 : 200),
    // },
    // [theme.breakpoints.up("md")]: {
    //   height: `300px !important`,
    //   marginBottom: theme.spacing(3),
    // },
    // [theme.breakpoints.up("lg")]: {
    //   height: `400px !important`,
    // },
  },
  bannerText: {
    color: "white",
    textAlign: (props) => (props.textOnRightHalfOnly ? "left" : "center"),
    fontWeight: 501,
    fontFamily: (props) =>
      props.textOnRightHalfOnly ? "Source Serif Pro" : "Open Sans",
    textShadow: `2px 2px 8px grey`,

    [theme.breakpoints.down("xs")]: {
      textAlign: (props) => (props.textOnRightHalfOnly ? "right" : "center"),
      fontSize: 44,
    },
  },
}));

export default Banner;
