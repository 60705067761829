import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useTheme from "@material-ui/core/styles/useTheme";

import {
  Grid,
  TextField,
  Typography,
  Button,
  CircularProgress
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

const ContactForm = ({ stringObj }) => {

  const initialFormValues = {
    contactName: "",
    contactEmail: "",
    message: ""
  };
  const inputFieldValues = [
    {
      name: "contactName",
      id: "contact-name"
    },
    {
      name: "contactEmail",
      id: "contact-email"
    },
    {
      name: "message",
      id: "ontact-message",
      multiline: true,
      rows: 10
    }
  ];

  const classes = useStyles();
  const isDesktop = useMediaQuery(useTheme().breakpoints.up("lg"));
  const [values, setValues] = useState(initialFormValues);
  const [errors, setErrors] = useState({});
  const [fetching, setFetching] = useState(false);
  const [submitResult, setSubmitResult] = useState('');

  const PostContactForm = async (contactValues) => {
    // do stuff
    if (!fetching) {
      setFetching(true);
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(contactValues)
      };
      let url = `${process.env.WP_REST_URL}/contact-form`;
      // let url = `http://fbig.smartdrop.com.hk/internal-site/wp-json/wp/v2/contact-form`;

      fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => {
          // console.log(data)
          if (data === "Message has been sent") {
            setValues({
              ...initialFormValues
            });
            setSubmitResult(stringObj.submitResultSuccess)
          } else {
            console.log("form:", data);
            setSubmitResult(stringObj.submitResultFail)
          }
          setFetching(false);
        }).catch(err => {
          console.log("form err:", err);
          setSubmitResult(stringObj.submitResultFail)
          setFetching(false);
        })
      return;
    }
  };


  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("contactName" in fieldValues)
      temp.contactName = fieldValues.contactName ? "" : "This field is required.";

    if ("contactEmail" in fieldValues) {
      temp.contactEmail = fieldValues.contactEmail ? "" : "This field is required.";
      if (fieldValues.contactEmail)
        temp.contactEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.contactEmail)
          ? ""
          : "Email is not valid.";
    }

    if ("message" in fieldValues) {
      temp.message = fieldValues.message.length !== 0 ? "" : "This field is required.";
    }

    setErrors({
      ...temp
    });
    return temp;
  };

  const handleInputValue = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
    // console.log("value:", values)
    if (errors[name]) {
      validate({ [name]: value });
    }
  };

  const formIsValid = (fieldValues = values) => {
    const isValid =
      fieldValues.contactName &&
      fieldValues.contactEmail &&
      fieldValues.message &&
      Object.values(errors).every((x) => x === "");

    return isValid;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // console.log("value:", values)
    let temp = validate(values);

    const isValid = Object.values(temp).every((x) => x === "") && formIsValid()
    if (isValid && !fetching) {
      await PostContactForm(values);
    }
  };
  return (
    <form autoComplete="off" onSubmit={handleFormSubmit}>
      {inputFieldValues.map((inputFieldValue, index) => {
        return (
          <Grid key={index}>
            <Typography key={"label-" + index} variant="body2">{stringObj[inputFieldValue.name]}<span className={classes.red}>*</span></Typography>
            <TextField
              key={"input-" + index}
              name={inputFieldValue.name}
              fullWidth
              variant="outlined"
              margin="dense"
              autoComplete="none"
              value={values[inputFieldValue.name]}

              multiline={inputFieldValue.multiline ?? false}
              rows={inputFieldValue.rows ?? 1}
              onChange={handleInputValue}
              onBlur={handleInputValue}
              error={errors[inputFieldValue.name] ? true : false}
              className={classes.textField}

              {...(errors[inputFieldValue.name] && {
                helperText: errors[inputFieldValue.name]
              })}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        );
      })}
      <span className={submitResult === stringObj.submitResultSuccess ? classes.green : classes.red }>{submitResult}</span>
      <Grid container justifyContent={isDesktop ? "flex-end" : "center"}>
        <Button disabled={fetching} type="submit" variant="outlined" className={classes.submitButton} >
          {fetching ? <CircularProgress></CircularProgress> : stringObj.send}
        </Button>
      </Grid>
    </form>
  );
};
// disabled={!formIsValid()}

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
    fontFamily: "Open Sans",
    fontWeight: 400,
  },
  form: {
    marginBottom: theme.spacing(4),
    display: "block",
  },
  textField: {
    marginBottom: theme.spacing(3),
    [`& fieldset`]: {
      borderRadius: 0,
    },
    "& input": {
      fontSize: 18,
    },
    "& textarea": {
      fontSize: 18,
    },
    "& p": {
      marginLeft: 0,
    },
  },
  submitButton: {
    borderRadius: 0,
    borderColor: theme.palette.error.main,
  },
  green:{
    color: theme.palette.success.main
  },
  red:{
    color: theme.palette.error.main
  }
}));

export default ContactForm;
